<template>
  <button @click="$store.dispatch('remote/authenticateWithGithub')">
    Login with Github
  </button>
</template>

<script>
export default {
  name: "LoginButton",
};
</script>

<style></style>
