<template>
  <div class="footer-container">
    <div class="left">
      <router-link :to="{ name: 'home' }">About</router-link>
      <!-- <a href="https://codewarbler.github.io/warbler/#examples">Examples</a> -->
      <!-- <a href="https://github.com/codewarbler/warbler">Github</a> -->
      <!-- <a href="https://twitter.com/codewarbler">Twitter</a> -->
      <span v-show="$store.state.midi.midiReceived" class="midi-log">{{
        $store.state.midi.lastReceivedEvent
      }}</span>
    </div>
    <div class="right">
      <span class="quickkeys">
        <b>Docs: </b>
        <a href="https://tonejs.github.io/docs/14.8.26/index.html">Tone.js</a>
        <a href="https://github.com/tonaljs/tonal#documentation">Tonal.js</a>
      </span>
      <span class="quickkeys">
        <b>Run:</b> Cmd + Return <b>Stop: </b> Cmd + .
      </span>
      <span>
        <abbr :title="`Build ${VUE_APP_GIT_REV} from ${VUE_APP_BUILD_DATE}`">
          Warbler {{ VUE_APP_WARBLER_VERSION }}
        </abbr>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    let date = new Date(parseInt(process.env.VUE_APP_BUILD_DATE, 10));
    return {
      VUE_APP_WARBLER_VERSION: process.env.VUE_APP_WARBLER_VERSION,
      VUE_APP_BUILD_DATE: date.toUTCString(),
      VUE_APP_GIT_REV: process.env.VUE_APP_GIT_REV,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer-container {
  color: #999;
  background-color: #222;
  font-size: 0.9em;
  span {
    margin-right: 18px;
  }
  a {
    color: #999;
    text-decoration: none;
    margin-right: 12px;
    &:hover {
      color: white;
      // text-decoration: underline;
    }
  }
  .left {
    float: left;
    margin-top: 10px;
    margin-left: 16px;
  }
  .right {
    margin-top: 10px;
    margin-right: 10px;
    float: right;
  }
  abbr {
    text-decoration: none;
  }
  .quickkeys {
    // font-size: 0.7em;
    margin-right: 22px;
    b {
      margin-left: 12px;
    }
  }
  .midi-log {
    color: #777;
    font-size: 0.7em;
  }
}
</style>
