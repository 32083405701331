<template>
  <div class="header">
    <div class="left">
      <router-link
        :to="{ name: 'home' }"
        style="text-decoration: none; color: inherit"
      >
        <span class="heading-1">Warbler </span>
      </router-link>
    </div>

    <div class="center">
      <div>
        <span class="running" v-if="$store.state.isRunning"> 🐥 </span>
        <button
          id="runButton"
          :class="{
            active: $store.state.isRunning,
            unapplied: unappliedChanges,
          }"
          @click="startFunction"
        >
          Run
        </button>
        <button id="stopButton" @click="closeContext">Stop</button>
        <!-- Hide the record button. It's used for offline rendering. -->
        <button id="recordButton" v-show="false" @click="$emit('record')">
          Record
        </button>
      </div>
    </div>
    <div class="right">
      <span>Name: </span>
      <input v-model="name" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    unappliedChanges: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      version: process.env.VUE_APP_WARBLER_VERSION,
      name: "",
    };
  },
  watch: {
    name(value) {
      this.$store.dispatch("remote/updateNameToFirestore", {
        name: value,
        id: this.$route.params.id,
      });
    },
  },
  mounted() {
    this.name = this.$store.state.remote.scriptDoc.name;
  },
  methods: {
    startFunction() {
      this.$emit("startFunction");
    },
    closeContext() {
      this.$emit("closeContext");
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  // height: 54px;
  background-color: lightblue;
}

.heading-1 {
  font-size: 2em;
  font-weight: bold;
}

.left {
  position: absolute;
  left: 12px;
  top: 6px;
  h1 {
    font-size: 2em;
    line-height: 0px;
  }
  z-index: 100;
  input {
    position: relative;
    top: -6px;
    background-color: transparent;
    width: 200px;
  }
}

.right {
  position: absolute;
  right: 12px;
  top: 11px;
}

.center {
  position: absolute;
  top: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  div {
    position: relative;
    .running {
      position: absolute;
      color: black;
      font-weight: bold;
      text-align: center;
      // text-shadow: 1px 1px 10px yellow;
      // margin-right: 10px;
      top: 10px;
      left: -306px;
      text-align: right;
      width: 300px;
      // background-color: pink;
    }
    .active {
      background-color: orange;
      color: black;
      // text-shadow: 1px 1px black;
      // font-weight: bold;
    }
  }
}

button {
  background-color: black;
  margin-left: 10px;
  border: none;
  cursor: pointer;
  color: white;
  padding: 12px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  &:hover {
    background-color: orange;
    color: black;
  }
}
.unapplied {
  // transform: rotate(3deg);
  border-bottom: 4px solid magenta;
}

label {
  margin-left: 16px;
  margin-top: 6px;
}
input {
  width: 140px;
  margin-left: 2px;
  margin-right: 20px;
  // height: 28px;
  padding: 6px 10px;
  // color: white;
  // background-color: #666;
  // margin: 8px 0;
  // box-sizing: border-box;
}
</style>
