<template>
  <div>
    <div class="canvas-div" @mousedown="emitCallback">
      <div ref="canvas" />
    </div>
    <!-- <div :style="style" class="ui" @click="emitCallback">
      <span v-if="signalObj">{{ signalObj.name }}</span>
      <span v-if="result">{{ result }}</span>
    </div> -->
  </div>
</template>

<script>
import P5 from "p5"; // Package from npm
export default {
  name: "Button",
  props: {
    signalObj: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: () => 160,
    },
    height: {
      type: Number,
      default: () => 100,
    },
  },
  data() {
    return {
      result: null,
    };
  },
  computed: {
    style() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
  },
  methods: {
    emitCallback() {
      console.log(this.signalObj);
      this.result = this.signalObj.callback();
    },
  },
  mounted() {
    let _v = this;
    let sketch = function (p) {
      // let x = 0;
      //let y = 0;
      let width = _v.width;
      let height = _v.height;

      p.setup = function () {
        p.createCanvas(width, height);
        p.frameRate(40);
      };

      p.draw = function () {
        // console.log(_v.signalObj);
        if (!_v.signalObj) {
          return;
        }
        p.background("black");
        p.fill("white");

        p.noStroke();
        p.fill("gray");
        let circleSize = 0;
        p.circle(p.mouseX, p.mouseY, circleSize);
        p.fill("white");

        if (
          p.mouseX > 0 &&
          p.mouseX < width &&
          p.mouseY > 0 &&
          p.mouseY < height
        ) {
          p.background("aqua");
          p.cursor("pointer");
          p.fill("pink");
          p.circle(p.mouseX, p.mouseY, circleSize);
          p.fill("black");
        }

        // p.line(p.mouseX, 0, p.mouseX, height);
        // p.line(0, p.mouseY, width, p.mouseY);
        // Text
        // Button

        // result
        p.textSize(10);
        if (_v.result) {
          p.textAlign(p.RIGHT, p.BASELINE);
          p.text(_v.result, width - 4, height - 4);
        }

        // name
        p.textAlign(p.RIGHT, p.BASELINE);
        p.text("button", width - 3, 11);

        let name = _v.signalObj.name;
        if (name) {
          p.textSize(16);
          p.textAlign(p.CENTER, p.CENTER);
          p.text(name, 0, height / 2, width);
        }
      };
    };
    let myp5 = new P5(sketch, this.$refs.canvas);
    console.log(myp5);
  },
};
</script>

<style lang="scss" scoped>
.ui {
  position: relative;
  background-color: pink;
  span {
    font-size: 10px;
  }
}
</style>
