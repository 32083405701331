<template>
  <div>
    <div class="canvas-div">
      <div ref="canvas" />
    </div>
  </div>
</template>

<script>
import P5 from "p5"; // Package from npm
export default {
  name: "Spectrum",
  props: {
    signalObj: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: () => 160,
    },
    height: {
      type: Number,
      default: () => 100,
    },
  },
  data() {
    return {};
  },
  mounted() {
    let _v = this;
    let sketch = function (p) {
      //let x = 0;
      //let y = 0;
      let width = _v.width;
      let height = _v.height;

      p.setup = function () {
        p.createCanvas(width, height);
        p.frameRate(40);
      };

      p.draw = function () {
        p.background(0);
        //p.fill(255);
        if (!_v.signalObj) return;
        let spectrum = _v.signalObj.fft.getValue();
        spectrum = spectrum.slice(1, 65);
        //console.log(spectrum);
        if (spectrum) {
          p.noStroke();
          for (let i = 0; i < spectrum.length; i++) {
            let x = p.map(i, 0, spectrum.length, 0, width);
            let h = -height + p.map(spectrum[i], -80, -20, height, 0);
            p.fill(i * 2, i, 255 - i * 2, 255);
            p.fill("magenta");
            p.rect(x, height, width / spectrum.length, h);
          }
        }
        // name
        p.noStroke();
        p.fill("white");
        p.textSize(10);
        p.textAlign(p.RIGHT);
        p.text(_v.signalObj.name, width - 3, 11);
      };
    };
    let myp5 = new P5(sketch, this.$refs.canvas);
    console.log(myp5);
  },
};
</script>

<style lang="scss" scoped>
.canvas-div {
  position: relative;
}
canvas {
  //background: pink;
  z-index: 1000;
}
</style>
