<template>
  <div>
    <div class="canvas-div">
      <div ref="canvas" />
    </div>
  </div>
</template>

<script>
import P5 from "p5"; // Package from npm
export default {
  name: "Meter",
  props: {
    signalObj: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: () => 160,
    },
    height: {
      type: Number,
      default: () => 100,
    },
  },
  data() {
    return {};
  },
  mounted() {
    let _v = this;
    let sketch = function (p) {
      // let x = 0;
      //let y = 0;
      let width = _v.width;
      let height = _v.height;

      p.setup = function () {
        p.createCanvas(width, height);
        p.frameRate(40);
      };

      p.draw = function () {
        // console.log(_v.signalObj);
        if (!_v.signalObj) {
          return;
        }
        p.background(0);
        p.fill("magenta");
        let adjustedWidth = width / 2;

        let leftNormalRangeValue = _v.signalObj.meter.getValue()[0];
        let rightNormalRangeValue = _v.signalObj.meter.getValue()[1];

        let centeredLeftX = (width - adjustedWidth) / 2 - 1;
        let centeredRightX = width / 2 + 1;

        p.rect(
          centeredLeftX,
          height - leftNormalRangeValue * height,
          adjustedWidth / 2,
          height + 20
        );
        p.rect(
          centeredRightX,
          height - rightNormalRangeValue * height,
          adjustedWidth / 2,
          height + 20
        );

        // Text
        // Draw name and value
        p.fill("white");
        p.textSize(10);
        let decimalPlaces = 2;
        p.textAlign(p.LEFT);
        p.text(leftNormalRangeValue.toFixed(decimalPlaces), 3, height - 4);
        p.textAlign(p.RIGHT);
        p.text(
          rightNormalRangeValue.toFixed(decimalPlaces),
          width - 4,
          height - 4
        );
        // name
        p.textSize(10);
        p.textAlign(p.RIGHT);
        p.text(_v.signalObj.name, width - 3, 11);
      };
    };
    let myp5 = new P5(sketch, this.$refs.canvas);
    console.log(myp5);
  },
};
</script>

<style lang="scss" scoped>
.canvas-div {
  position: relative;
}
canvas {
  //background: pink;
  z-index: 1000;
}
</style>
