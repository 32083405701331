<template>
  <div>
    <div class="canvas-div">
      <div ref="canvas" />
    </div>
  </div>
</template>

<script>
import P5 from "p5"; // Package from npm
export default {
  name: "Waveform",
  props: {
    signalObj: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: () => 160,
    },
    height: {
      type: Number,
      default: () => 100,
    },
  },
  data() {
    return {
      values: [],
      min: null,
      max: null,
    };
  },
  mounted() {
    let _v = this;
    this.values = new Array(_v.width).fill(0);
    let sketch = function (p) {
      let x = 0;
      //let y = 0;
      let width = _v.width;
      let height = _v.height;

      p.setup = function () {
        p.createCanvas(width, height);
        p.frameRate(30);
      };

      p.draw = function () {
        // console.warn(_v.signalObj);
        if (!_v.signalObj) {
          return;
        }

        p.background(0);
        p.stroke("#00adad");
        // console.warn(_v.signalObj);
        let value;
        if (_v.signalObj.renderValue) {
          value = _v.signalObj.renderValue(_v.signalObj.signal);
        } else {
          value = _v.signalObj.meter.getValue();
        }

        // console.log(name);
        // let value = _v.signalObj.value;

        if (_v.min == null || _v.min > value) {
          _v.min = value;
        }

        if (_v.max == null || _v.max < value) {
          _v.max = value;
        }

        // Old, explicit way. min and max are pre-defined.
        // This uses the min/max from the Auto object.
        // let min = _v.signalObj.min;
        // let max = _v.signalObj.max;

        // Possible new way that saves the min/max and applies it moving forward.
        let min = _v.min;
        let max = _v.max;

        if (_v.signalObj.approxValue) {
          value = _v.signalObj.approxValue;
        }
        let percent = 1 - (value - min) / (max - min);
        let yValue = percent * height;
        _v.values.shift();
        _v.values.push(yValue);
        for (let i = 0; i < _v.values.length; i++) {
          p.line(x + i, _v.values[i], x + i, height);
        }

        // Draw name and value
        p.fill("white");
        p.textSize(10);
        let decimalPlaces = 2;
        // min
        p.textAlign(p.LEFT);
        p.text(_v.max.toFixed(decimalPlaces), 3, 11);
        // max
        p.text(_v.min.toFixed(decimalPlaces), 3, height - 4);
        p.textSize(10);
        // value
        p.textAlign(p.RIGHT);
        p.text(value.toFixed(decimalPlaces), width - 4, height - 4);

        // name
        p.textAlign(p.RIGHT);
        p.text(_v.signalObj.name, width - 3, 11);
      };
    };
    let myp5 = new P5(sketch, this.$refs.canvas);
    console.log(myp5);
  },
};
</script>

<style lang="scss" scoped>
.canvas-div {
  position: relative;
}
canvas {
  z-index: 1000;
}
</style>
