<template>
  <button @click="$store.dispatch('remote/logout')">Logout</button>
</template>

<script>
export default {
  name: "LogoutButton",
};
</script>

<style></style>
