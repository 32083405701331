<template>
  <div class="editor-component">
    <Header
      id="header"
      :unapplied-changes="hasUnappliedChanges"
      @startFunction="emitChange"
      @closeContext="closeContext"
      @record="record"
      class="header"
    />
    <div :style="visualsStyle" class="visuals">
      <div
        v-for="(signalObj, idx) in signalObjects"
        :key="signalObj.name + idx"
        :class="{ active: isLineActive(signalObj) }"
        @mouseover="makeLinesActive(signalObj)"
        @mouseleave="makeLinesInactive(signalObj)"
      >
        <Scope
          v-if="signalObj.type == 'scope'"
          :signal-obj="signalObj"
          :width="visualsWidth"
          :height="visualsHeight"
        />
        <Spectrum
          v-if="signalObj.type == 'spectrum'"
          :signal-obj="signalObj"
          :width="visualsWidth"
          :height="visualsHeight"
        />
        <Waveform
          v-if="signalObj.type == 'waveform'"
          :signal-obj="signalObj"
          :width="visualsWidth"
          :height="visualsHeight"
        />
        <Meter
          v-if="signalObj.type == 'meter'"
          :signal-obj="signalObj"
          :width="visualsWidth"
          :height="visualsHeight"
        />
        <Button
          v-if="signalObj.type == 'button'"
          :signal-obj="signalObj"
          :width="visualsWidth"
          :height="visualsHeight"
        />
        <Slider
          v-if="signalObj.type == 'slider'"
          :signal-obj="signalObj"
          :idx="idx"
          :width="visualsWidth"
          :height="visualsHeight"
        />
      </div>
      <div v-if="hasError" class="error">
        <h1>{{ errorName }}</h1>
        <p>Near line number {{ errorLine }}</p>
        <code>{{ errorMessage }}</code>
      </div>
    </div>
    <div ref="editor" :style="editorStyle" class="editor" />
    <Footer class="footer" />
    <Notification class="notification" />
  </div>
</template>

<script>
import * as monaco from "monaco-editor";
// import * as Tone from "@/tone";
// import * as Tonal from "@tonaljs/tonal";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Notification from "@/components/Notification";
import Meter from "@/components/CodeWarblerUi/Meter";
import Waveform from "@/components/CodeWarblerUi/Waveform";
import Spectrum from "@/components/CodeWarblerUi/Spectrum";
import Scope from "@/components/CodeWarblerUi/Scope";
import Button from "@/components/CodeWarblerUi/Button";
import Slider from "@/components/CodeWarblerUi/Slider";

const Tone = window.Tone;
const Tonal = window.Tonal;

import { createWavFromBlob } from "@/utils/recorder";
import { CodeWarblerVisuals } from "@/CodeWarbler";
// import sample from "lodash/sample";
import _ from "lodash";
import Mousetrap from "mousetrap";

import { WebMidi } from "webmidi";
import WebMonome from "webmonome";

console.log(WebMidi);

const version = process.env.VUE_APP_TONE_DEFINITIONS_VERSION;

export default {
  name: "Editor",
  components: {
    Header,
    Footer,
    Meter,
    Waveform,
    Spectrum,
    Scope,
    Slider,
    Button,
    Notification,
  },
  props: {},
  data() {
    return {
      editor: null,
      output: null,
      text: "",
      lastExecutedText: "",
      windowWidth: 100,
      windowHeight: 1000,
      headerHeight: 54,
      footerHeight: 40,
      editorLeft: 400,
      hasError: false,
      errorName: "name",
      errorLine: 0,
      errorMessage: "",
      signalObjects: [],
      visualSizes: {
        small: [120, 90],
        medium: [185, 138],
        large: [380, 90],
      },
      visualSize: "small",
      cursorLineNumber: 0,
      decorations: null,
      recording: false,
    };
  },
  computed: {
    visualsWidth() {
      return this.visualSizes[this.visualSize][0];
    },
    visualsHeight() {
      return this.visualSizes[this.visualSize][1];
    },
    editorStyle() {
      let height = this.windowHeight - this.headerHeight - this.footerHeight;
      let width = this.windowWidth - this.editorLeft;
      let _style = {
        left: `${this.editorLeft}px`,
        width: `${width}px`,
        height: `${height}px`,
        backgroundColor: "black",
      };
      return _style;
    },
    visualsStyle() {
      let height = this.windowHeight - this.headerHeight - this.footerHeight;
      return {
        left: `0px`,
        width: `${this.editorLeft}px`,
        height: `${height}px`,
        "grid-template-columns": `repeat(auto-fill, ${this.visualsWidth}px)`,
        "grid-auto-rows": `${this.visualsHeight}px`,
        backgroundColor: "teal",
      };
    },
    hasUnappliedChanges() {
      return this.text != this.lastExecutedText;
    },
  },
  async beforeDestroy() {
    console.log("beforeDestroy");
    await this.closeContext();
  },
  mounted() {
    // this.text = this.$store.getters.activeFile.code;
    this.text = this.$store.state.remote.scriptDoc.scriptText;

    // Randomize the visual sizes during development.
    // if (process.env.NODE_ENV == "development") {
    //   this.visualSize = sample(Object.keys(this.visualSizes));
    // }
    this.loadDefinition();
    this.$store.dispatch("midi/setupMidiListeners");

    // Setup the key captures for outside of Monaco.
    // Monaco handles the key capture inclusive.

    // CMD-S
    Mousetrap.bind("command+s", () => {
      this.$store.dispatch("saveCurrentToFile");
      return false;
    });
    // CMD-Return
    Mousetrap.bind("command+return", () => {
      this.emitChange();
      return false;
    });
    // CMD-O
    Mousetrap.bind("command+o", () => {
      this.$store.dispatch("openFile");
      return false;
    });
    // CMD-.
    Mousetrap.bind("command+.", () => {
      this.closeContext();
      return false;
    });
  },
  methods: {
    createEditor() {
      console.warn("createEditor");

      const el = this.$refs.editor;
      const model = monaco.editor.createModel(this.text, "typescript");
      this.editor = monaco.editor.create(el, {
        model,
        theme: "vs-dark",
        scrollBeyondLastLine: false,
        multiCursorModifier: "ctrlCmd",
        minimap: {
          enabled: false,
        },
        lineNumbers: "on",
      });

      this.$store.commit("setEditorReference", this.editor);

      // Theme override.
      monaco.editor.defineTheme("transparentBgTheme", {
        base: "vs-dark",
        inherit: true,

        rules: [{ background: "000000" }],
        colors: {
          "editor.background": "#000000",
          // "editorInlayHint.foreground": "#00FF00",
          // "editorInlayHint.background": "#FF00FF",
          // "editor.hoverHighlightBackground": "pink"
          peekWidgetDefaultFocus: "tree",
        },
      });
      monaco.editor.setTheme("transparentBgTheme");

      // Add prettier as formatter.
      monaco.languages.registerDocumentFormattingEditProvider("typescript", {
        async provideDocumentFormattingEdits(model, options, token) {
          console.log(options, token);
          const prettier = await import("prettier/standalone");
          const babel = await import("prettier/parser-babel");
          const text = prettier.format(model.getValue(), {
            parser: "babel",
            plugins: [babel],
            singleQuote: false,
            printWidth: 80,
          });

          return [
            {
              range: model.getFullModelRange(),
              text,
            },
          ];
        },
      });

      monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
        target: monaco.languages.typescript.ScriptTarget.ES2015,
        allowNonTsExtensions: true,
        lib: ["ES2015"],
      });

      monaco.languages.typescript.typescriptDefaults.addExtraLib(
        this.declr,
        "file:///node_modules/tone/index.d.ts"
      );

      monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
        noSemanticValidation: true,
        noSyntaxValidation: true,
      });

      // Does not include the DOM intellisense, which is awesome!
      monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
        noLib: false,
        allowNonTsExtensions: true,
        lib: ["es2019"],
      });

      // Remove quickfix shortcut so you can reassign it to "stop tone"
      // https://github.com/microsoft/monaco-editor/issues/102#issuecomment-701704517
      this.editor._standaloneKeybindingService.addDynamicKeybinding(
        "-editor.action.quickFix",
        null,
        () => {}
      );

      this.editor.onDidChangeModelContent(() => {
        const value = this.editor.getValue();
        if (this.text !== value) {
          // console.log(value, event);
          this.text = value;
          // this.parseSyntax();
          // If you add syntax parsing, do it here.
        }
      });

      this.editor.onDidChangeCursorPosition(() => {
        // console.log(this.editor.getPosition().lineNumber);
        this.cursorLineNumber = this.editor.getPosition().lineNumber;
      });

      var _this = this;
      /*
      Quick keys:
        Save/run on CMD-S or CMD-Enter
        Stop on CMD-.
      */
      this.editor.addCommand(
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
        function () {
          console.warn("RUN pressed!");
          _this.emitChange();
        }
      );

      this.editor.addCommand(
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S,
        function () {
          console.warn("SAVE pressed!");
          // _this.$store.dispatch("saveCurrentToFile");
          _this.emitChange();
        }
      );

      this.editor.addCommand(
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.US_DOT,
        function () {
          console.warn("Stop pressed!");
          _this.closeContext();
        }
      );

      // this.editor.addCommand(
      //   monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_O,
      //   function () {
      //     console.warn("Open pressed!");
      //     _this.$store.dispatch("openFile");
      //   }
      // );

      window.addEventListener("resize", () => {
        _this.resize();
      });
      _this.resize();
    },
    emitChange() {
      // Create new context, then close the existing context.
      // The order and synchronous is important,
      // as Safari chokes on Tone.start behind an await.
      let existingContext = Tone.getContext();
      // Use Tone.Context for making all new contexts (which uses standardized-audio-context).
      let audioCtx = new Tone.Context();
      Tone.setContext(audioCtx);
      this.toneContext = audioCtx;
      Tone.start();

      let _this = this;
      this.editor
        .getAction("editor.action.formatDocument")
        .run()
        .then(() => {
          // console.log("format complete, emit change now");
          // console.log(_this.text);
          _this.startFunction();
          // this.safariStartFunction();
          _this.$store.dispatch("updateCode", this.text);
          _this.$store.dispatch("remote/updateScriptToFirestore", {
            scriptText: this.text,
            id: this.$route.params.id,
          });

          _this.hasError = false;
          if (existingContext && existingContext.state != "closed") {
            // Close the existing context.
            console.warn("toneContext closed.");
            existingContext.close();
          }
        })
        .catch((e) => {
          console.log(e.name, e.message, e.stack, e.lineNumber);
          _this.hasError = true;
          _this.errorName = e.name;
          if (e.stack.includes("<anonymous>:")) {
            let line = parseInt(
              e.stack.split("<anonymous>:")[1].split(":")[0],
              10
            );
            _this.errorLine = line - 2;
          }

          _this.errorMessage = e.message;
          // Restore previous Tone context if available.
          Tone.setContext(existingContext);
          _this.toneContext = existingContext;
        });
    },
    async closeContext() {
      console.log("closeContext");
      if (this.toneContext) {
        await this.toneContext.close();
        this.toneContext = null;
        console.warn("toneContext closed.");
        this.$store.commit("setIsNotRunning");
      }
    },
    startFunction() {
      let CodeWarbler = new CodeWarblerVisuals(Tone);

      // Add the items for main destination.
      let dest = Tone.getDestination();
      CodeWarbler.meter(dest, "Main");
      CodeWarbler.spectrum(dest, "Main");
      CodeWarbler.scope(dest, "Main");

      if (this.recording) {
        this.recording = false;
        const recorder = new Tone.Recorder();
        dest.connect(recorder);
        recorder.start();
        let recordingSeconds = 10;
        let _v = this;
        setTimeout(async () => {
          // the recorded audio is returned as a blob
          const recording = await recorder.stop();
          console.log("recording", recording);
          createWavFromBlob(recording, "codetext");

          console.log(_v.toneContext);
          _v.closeContext();
        }, recordingSeconds * 1000);
      }

      // Corrections for Tone code.
      let functionCode = this.text.replaceAll(
        "Tone.Transport",
        "Tone.getTransport()"
      );

      functionCode =
        functionCode +
        `
      Ui.toneObjectDeclarations.forEach((i) => {
        Ui._autoDisplay(eval(i.identifier), i);
      });`;

      CodeWarbler.evaluateSource(functionCode);

      functionCode =
        functionCode +
        `
        // Run at the end of the function code.
        const addCompressorToChain = true;
        // Add an aggressive compressor as a limiter for user safety.
        // Requires patched Tone.js.
        const __destination = Tone.getDestination();
        const __compressor = new Tone.Compressor({
          attack: 0,
          knee: 0,
          ratio: 20,
          release: 0.5,
          threshold: 0,
        });
        if(__destination.chainItems){
            const __chainItems = [...__destination.chainItems];
            __chainItems.forEach(n => n.disconnect());
            __chainItems.push(__compressor);
            __destination.chain(...__chainItems);
        }else{
          console.warn("Tone.js is unpatched. Missing destination.chainItems.");
        }
        if (typeof title !== 'undefined'){
          Ui._title = title;
        }
      `;

      // Run the code, passing in values.
      new Function(
        "Tone",
        "Tonal",
        "_",
        "WebMidi",
        "WebMonome",
        "CodeWarbler",
        "Ui",
        functionCode
      )(Tone, Tonal, _, WebMidi, WebMonome, CodeWarbler, CodeWarbler);

      this.signalObjects = CodeWarbler.signalObjects;
      this.$store.commit("setIsRunning");
      this.$store.dispatch("updateTitle", CodeWarbler._title);
      this.lastExecutedText = this.text;
    },
    loadDefinition() {
      const url = `https://tonejs.github.io/docs/${version}/assets/tone.d.ts`;

      let _v = this;
      fetch(url)
        .then((res) => {
          return res.text();
        })
        .then((declr) => {
          /**
           * Add the declaration
           */
          // wrap it in the namespace instead of module declaration
          declr = declr.replace("declare module 'tone' {", "namespace Tone {");

          // add the console
          declr += `
interface Console {
  log(message?: any, ...optionalParams: any[]): void;
}
declare var Console: {
  prototype: Console;
  new(): Console;
};
const console = new Console();

// Declaration for Ui needs to be completed by hand here.
/**
* Ui object
*/
namespace Ui {
      /**
      * Make the display.
      */
      declare function display(signal: any, label?: string): void;
      /**
      * Make the scope.
      */
      declare function scope(signal: any, label?: string): void;
      /**
      * Make the meter.
      */
      declare function meter(signal: any, label?: string): void;
      /**
      * Make the waveform.
      */
      declare function waveform(signal: any, label?: string): void;
      /**
      * Make the spectrum.
      */
      declare function spectrum(signal: any, label?: string): void;
      /**
      * Make the button.
      */
      declare function button(onClickedFunction: () => string, label?: string): void;
      /**
      * Make the slider.
      */
      declare function slider(onChangedFunction: (value) => string, label?: string): void;
}


`;

          _v.declr = declr;
          _v.createEditor();
        });
    },
    resize() {
      // Triggered every resize.
      console.log("resize");
      this.windowWidth = document.documentElement.clientWidth;
      this.windowHeight = window.innerHeight;

      var _this = this;
      this.$nextTick(function () {
        _this.editor.layout();
      });
    },
    isLineActive(signalObj) {
      if (
        this.cursorLineNumber >= signalObj.locStart &&
        this.cursorLineNumber <= signalObj.locEnd &&
        signalObj.locStart != null
      ) {
        return true;
      }
      return false;
    },
    makeLinesActive(signalObj) {
      if (!signalObj.locStart) {
        return;
      }
      // console.log("active", signalObj.locStart, signalObj.locEnd);
      this.decorations = this.editor.deltaDecorations(this.decorations || [], [
        {
          range: new monaco.Range(signalObj.locStart, 1, signalObj.locEnd, 1),
          options: {
            isWholeLine: true,
            linesDecorationsClassName: "lineHighlightDecoration",
          },
        },
      ]);
      // console.log(this.editor.deltaDecorations);
    },
    makeLinesInactive() {
      if (this.decorations) {
        this.decorations = this.editor.deltaDecorations(this.decorations, []);
      }
    },
    record() {
      this.recording = true;
      this.emitChange();
    },
  },
};
</script>

<style lang="scss">
.editor-component {
  position: absolute;
  left: 0px;
  height: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0px;
  background-color: teal;
  p {
    button {
      margin-left: 5px;
    }
    select {
      margin-left: 5px;
    }
  }
  .visuals {
    margin: 10px;
    position: absolute;
    display: grid;
    gap: 10px;
    div {
      position: relative;
    }
    .error {
      padding-left: 20px;
      h1 {
        font-size: 1.4em;
        line-height: 2px;
      }
      p {
        font-size: 0.9em;
      }
      position: absolute;
      top: 80px;
      width: 100%;
      background-color: rgba(255, 255, 0, 0.94);
      padding-bottom: 30px;
    }
    .active {
      outline: 3px solid orange;
    }
  }
  .editor {
    position: absolute;
    .lineHighlightDecoration {
      background: lightblue;
      background: orange;
      width: 3px !important;
      margin-left: 8px;
    }
  }
  .footer {
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 40px;
    width: 100%;
    overflow: hidden;
  }
  .header {
    height: 54px;
  }
  .notification {
    position: absolute;
    z-index: 1000;
    bottom: 50px;
  }
}
</style>
