import router from "@/router";

import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  onAuthStateChanged,
  browserLocalPersistence,
  GithubAuthProvider,
  setPersistence,
} from "firebase/auth";

import {
  query,
  orderBy,
  limit,
  where,
  getDocs,
  getFirestore,
  addDoc,
  collection,
  Timestamp,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCshMWwFKcVrhMFHvtNqwg_6-3L7dIoRP4",
  authDomain: "warblertonejs.firebaseapp.com",
  projectId: "warblertonejs",
  storageBucket: "warblertonejs.appspot.com",
  messagingSenderId: "259726148181",
  appId: "1:259726148181:web:cfd29698e841355d988adc",
};

const limitScriptLoadNum = 20;

export default {
  namespaced: true,
  state: {
    user: null,
    isAuthenticated: false,
    scriptLoaded: false,
    scriptDoc: {},
    userScripts: [],
  },
  getters: {},
  mutations: {},
  actions: {
    async updateScriptToFirestore({ state }, value) {
      // Update doc
      state.scriptDoc.scriptText = value.scriptText;
      let docId = value.id;
      const docRef = doc(state.db, "scripts", docId);
      let update = {
        updatedDate: Timestamp.now(),
        scriptText: value.scriptText,
      };
      await setDoc(docRef, update, { merge: true });

      // Update index
      const indexRef = doc(state.db, "scripts-user-index", state.user.uid);
      let indexDocData = {
        id: docId,
        updatedDate: Timestamp.now(),
      };
      setDoc(indexRef, { [docId]: indexDocData }, { merge: true });
    },
    async updateNameToFirestore({ state }, value) {
      // Update doc
      state.scriptDoc.name = value.name;
      let docId = value.id;
      const docRef = doc(state.db, "scripts", docId);
      let update = {
        updatedDate: Timestamp.now(),
        name: value.name,
      };
      await setDoc(docRef, update, { merge: true });

      // Update index
      const indexRef = doc(state.db, "scripts-user-index", state.user.uid);
      let indexDocData = {
        id: docId,
        name: value.name,
        updatedDate: Timestamp.now(),
      };
      setDoc(indexRef, { [docId]: indexDocData }, { merge: true });
    },
    async deleteScriptById({ state, dispatch }, id) {
      // Update doc
      let docId = id;
      const docRef = doc(state.db, "scripts", docId);
      let update = {
        deleted: true,
        updatedDate: Timestamp.now(),
      };
      await setDoc(docRef, update, { merge: true });

      // Update index
      const indexRef = doc(state.db, "scripts-user-index", state.user.uid);
      let indexDocData = {
        id: docId,
        updatedDate: Timestamp.now(),
        deleted: true,
      };
      await setDoc(indexRef, { [docId]: indexDocData }, { merge: true });

      return dispatch("loadMyScripts");
    },
    async createNewScript(
      { state },
      initialData = { scriptText: "// Script goes here", name: "Untitled" }
    ) {
      // Add a new document in collection "cities"
      console.log("createNewScript");
      // Make new script.
      const colRef = collection(state.db, "scripts");
      const docRef = await addDoc(colRef, {
        name: initialData.name,
        scriptText: initialData.scriptText,
        userId: state.user.uid,
        createdDate: Timestamp.now(),
        updatedDate: Timestamp.now(),
        isPublic: false,
        deleted: false,
      });
      // Append to user index.
      let docId = docRef.id;
      const indexRef = doc(state.db, "scripts-user-index", state.user.uid);
      let indexDocData = {
        name: initialData.name,
        id: docId,
        createdDate: Timestamp.now(),
        isPublic: false,
        deleted: false,
      };
      setDoc(indexRef, { [docId]: indexDocData }, { merge: true });
      router.push({ name: "editor", params: { id: docRef.id } });
    },
    async loadMyScripts({ state }) {
      // Add a new document in collection "cities"
      console.log("loadMyScripts", state.user.uid);
      const colRef = collection(state.db, "scripts");
      const q = query(
        colRef,
        where("userId", "==", state.user.uid),
        where("deleted", "!=", true),
        orderBy("deleted", "desc"),
        orderBy("updatedDate", "desc"),
        limit(limitScriptLoadNum)
      );
      const querySnapshot = await getDocs(q);
      console.log("loaded!");
      let scripts = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        let data = {
          data: doc.data(),
          key: doc.id,
        };
        scripts.push(data);
      });
      state.userScripts = scripts;
    },
    async loadEditById({ state }, id) {
      console.log("loadEditById", id);

      state.scriptLoaded = false;
      state.scriptDoc = null;

      const docRef = doc(state.db, "scripts", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        state.scriptDoc = docSnap.data();
        state.scriptLoaded = true;
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        state.scriptLoaded = false;
      }
    },
    async duplicateById({ state, dispatch }, id) {
      console.log("duplicateById", id);

      state.scriptLoaded = false;
      state.scriptDoc = null;

      const docRef = doc(state.db, "scripts", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        let scriptDoc = docSnap.data();
        return dispatch("createNewScript", {
          scriptText: scriptDoc.scriptText,
          name: `Copy of ${scriptDoc.name}`,
        });
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        state.scriptLoaded = false;
      }
    },
    connectToFirestore({ state }) {
      const app = initializeApp(firebaseConfig);

      // Initialize Firebase Authentication and get a reference to the service
      const auth = getAuth(app);

      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          // const uid = user.uid;
          state.user = user;
          state.isAuthenticated = true;
          console.log("user", user);
          // ...
        } else {
          // User is signed out
          // ...
          console.log("not user");
          state.user = null;
          state.isAuthenticated = false;
        }
      });

      // Set the connection to the DB.
      state.db = getFirestore(app);
      state.connected = true;
    },
    authenticateWithGithub() {
      console.log("authenticateWithGithub");
      const provider = new GithubAuthProvider();
      provider.addScope("");

      const auth = getAuth();

      setPersistence(auth, browserLocalPersistence)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return signInWithPopup(auth, provider)
            .then((result) => {
              // This gives you a GitHub Access Token. You can use it to access the GitHub API.
              const credential =
                GithubAuthProvider.credentialFromResult(result);
              const token = credential.accessToken;
              console.log(token);

              // The signed-in user info.
              const user = result.user;
              console.log(user);

              // ...
            })
            .catch((error) => {
              // Handle Errors here.
              const errorCode = error.code;
              const errorMessage = error.message;
              // The email of the user's account used.
              const email = error.customData.email;
              // The AuthCredential type that was used.
              const credential = GithubAuthProvider.credentialFromError(error);
              console.log(errorCode, errorMessage, email, credential);
              // ...
            });
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
        });
    },
    async logout() {
      const auth = getAuth();
      await auth.signOut();
      console.log("logged out");
    },
  },
};
