<template>
  <div>
    <Editor v-if="loaded" />
  </div>
</template>

<script>
import Editor from "@/components/Editor";

export default {
  name: "EditorContainer",
  components: { Editor },
  data() {
    return {
      loaded: false,
    };
  },
  async mounted() {
    await this.$store.dispatch("remote/connectToFirestore");
    await this.$store.dispatch("remote/loadEditById", this.$route.params.id);
    this.loaded = true;
  },
};
</script>

<style></style>
