<template>
  <div>
    <h1>Home</h1>
    <Authentication />
    <div v-if="$store.state.remote.isAuthenticated">
      <button @click="$store.dispatch('remote/createNewScript')">New</button>
      <ScriptList />
    </div>
  </div>
</template>

<script>
import Authentication from "@/components/Authentication.vue";
import ScriptList from "@/components/ScriptList.vue";

export default {
  name: "Home",
  components: { Authentication, ScriptList },
  mounted() {
    console.log("Home");
  },
};
</script>

<style></style>
