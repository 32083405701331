<template>
  <div>
    <h1>My Scripts</h1>
    <div v-for="script in $store.state.remote.userScripts" :key="script.id">
      <p>
        {{ script.data.name }} -
        {{ script.data.updatedDate.toDate().toLocaleString() }}
        <router-link :to="{ name: 'editor', params: { id: script.key } }">
          <button>Edit</button>
        </router-link>

        <button @click="() => duplicate(script.key)">Duplicate</button>
        <button @click="() => confirmDelete(script.key)">Delete</button>
      </p>
    </div>
    <!-- {{ $store.state.userScripts }} -->
  </div>
</template>

<script>
export default {
  name: "ScriptList",
  async mounted() {
    await this.$store.dispatch("remote/connectToFirestore");
    await this.$store.dispatch("remote/loadMyScripts");
  },
  methods: {
    duplicate(key) {
      // console.log(key);
      this.$store.dispatch("remote/duplicateById", key);
    },
    confirmDelete(key) {
      if (confirm("Delete script?") == true) {
        this.$store.dispatch("remote/deleteScriptById", key);
      }
    },
  },
};
</script>

<style></style>
