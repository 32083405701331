<template>
  <div>
    <div class="canvas-div" @mousedown="mouseDown" @mousemove="mouseDrag">
      <div ref="canvas" />
    </div>
    <!-- <div
      :style="style"
      class="ui"
      @mousedown="mouseDown"
      @mousemove="mouseDrag"
    >
      <span v-if="result">{{ result }}</span>
    </div> -->
  </div>
</template>

<script>
import isUndefined from "lodash/isUndefined";
import P5 from "p5"; // Package from npm
export default {
  name: "Slider",
  props: {
    signalObj: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: () => 160,
    },
    height: {
      type: Number,
      default: () => 100,
    },
    idx: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      result: null,
      value: null,
      callbackCalledOnce: false,
      mousedown: false,
      yPosition: 0,
    };
  },
  computed: {
    style() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
    midiCC() {
      if (!isUndefined(this.signalObj.midi?.cc)) {
        return `midi cc: ${this.signalObj.midi?.cc}`;
      }
      return "";
    },
  },
  methods: {
    mouseDown(evt) {
      window.addEventListener("mouseup", this.mouseUp);
      this.mousedown = true;
      this.mouseDrag(evt);
    },
    mouseUp() {
      this.mousedown = false;
      window.removeEventListener("mouseup", this.mouseUp);
    },
    mouseDrag(evt) {
      if (this.mousedown) {
        // console.log(evt.offsetY / this.height, evt);
        let y = evt.offsetY;
        if (y > this.height) y = this.height;
        if (y < 0) y = 0;
        let value = 1 - y / this.height;
        this.value = value;
        this.result = this.signalObj.callback(value);
        this.yPosition = y;
        this.callbackCalledOnce = true;
      }
    },
    midiEvent(evt) {
      let eventIsValid = this.signalObj.midi?.cc == evt.cc;
      if ("channel" in this.signalObj.midi && eventIsValid) {
        eventIsValid = this.signalObj.midi?.channel == evt.channel;
      }
      if (eventIsValid) {
        this.value = evt.value;
        this.result = this.signalObj.callback(evt.value);
        this.yPosition = this.height * (1 - evt.value);
        this.callbackCalledOnce = true;
      }
    },
  },
  mounted() {
    // Register Midi callback.
    this.$store.dispatch("midi/registerCcCallback", this.midiEvent);
    // Setup visuals.
    let _v = this;

    if (!isUndefined(_v.signalObj.initial)) {
      let value = _v.signalObj.initial;
      _v.result = this.signalObj.callback(value);
      _v.yPosition = (1 - _v.signalObj.initial) * _v.height;
      _v.callbackCalledOnce = true;
    }

    let sketch = function (p) {
      // let x = 0;
      //let y = 0;
      let width = _v.width;
      let height = _v.height;

      p.setup = function () {
        p.createCanvas(width, height);
        p.frameRate(40);
      };

      p.draw = function () {
        // console.log(_v.signalObj);
        if (!_v.signalObj) {
          return;
        }
        p.background("black");
        p.fill("white");

        p.noStroke();
        p.fill("gray");
        let circleSize = 0;
        p.circle(p.mouseX, p.mouseY, circleSize);
        p.fill("white");

        // Only display the value if there is a value.
        if (
          p.mouseX > 0 &&
          p.mouseX < width &&
          p.mouseY > 0 &&
          p.mouseY < height
        ) {
          p.background("black");
          p.cursor("ns-resize");
          p.fill("pink");
          p.circle(p.mouseX, p.mouseY, circleSize);
          p.fill("#666");
        } else {
          p.fill("#555");
        }

        if (_v.callbackCalledOnce) {
          p.fill("#555");
          p.rect(0, _v.yPosition, width, height - _v.yPosition);
        }

        p.fill("white");

        // value
        p.textSize(10);
        if (_v.value != null) {
          p.textAlign(p.RIGHT, p.BASELINE);
          p.text(_v.value.toFixed(2), width - 4, height - 4);
        }

        // result
        if (_v.result != null) {
          p.textAlign(p.LEFT, p.BASELINE);
          p.text(_v.result, 3, height - 4);
        }

        if (_v.midiCC) {
          p.textAlign(p.LEFT, p.BASELINE);
          p.text(_v.midiCC, 3, 11);
        }

        // name
        p.textAlign(p.RIGHT, p.BASELINE);
        p.text("slider", width - 3, 11);

        let name = _v.signalObj.name;
        if (name) {
          p.textSize(16);
          p.textAlign(p.CENTER, p.CENTER);
          p.text(name, 0, height / 2, width);
        }
      };
    };
    let myp5 = new P5(sketch, this.$refs.canvas);
    console.log(myp5);
  },
};
</script>

<style lang="scss" scoped>
.ui {
  position: relative;
  background-color: orange;
  span {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }
}
</style>
