import Vue from "vue";
import VueRouter from "vue-router";

import EditorContainer from "@/components/EditorContainer";
import Home from "@/pages/Home";

Vue.use(VueRouter);

var routes = [
  { path: "/", component: Home, name: "home" },
  { path: "/editor/:id", component: EditorContainer, name: "editor" },
];

const router = new VueRouter({
  mode: "history",
  // base: `/warbler/${process.env.VUE_APP_WARBLER_VERSION}/`,
  routes, // short for `routes: routes`
});

export default router;
