<template>
  <div>
    <div class="canvas-div">
      <div ref="canvas" />
    </div>
  </div>
</template>

<script>
import P5 from "p5"; // Package from npm
export default {
  name: "Scope",
  props: {
    signalObj: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: () => 160,
    },
    height: {
      type: Number,
      default: () => 100,
    },
  },
  data() {
    return {};
  },
  mounted() {
    let _v = this;
    let sketch = function (p) {
      //let x = 0;
      //let y = 0;
      let width = _v.width;
      let height = _v.height;

      p.setup = function () {
        p.createCanvas(width, height);
        //p.frameRate(20);
      };

      p.draw = function () {
        p.background(0);
        p.fill(255);
        if (!_v.signalObj) return;
        let buffer = _v.signalObj.waveform.getValue();
        drawWaveform(p, buffer, "magenta", 0, 0, width, height);

        // name
        p.noStroke();
        p.textSize(10);
        p.textAlign(p.RIGHT);
        p.text(_v.signalObj.name, width - 3, 11);
      };
    };
    let drawWaveform = function (p, buffer, color, x, y, width, height) {
      p.stroke(color);
      p.strokeWeight(0.7);
      // look a trigger point where the samples are going from
      // negative to positive
      let start = 0;
      let upCheck = true;
      for (let i = 1; i < buffer.length; i++) {
        if (upCheck) {
          if (buffer[i - 1] > 0 && buffer[i] <= 0) {
            start = i;
            break; // interrupts a for loop
          }
        } else {
          if (buffer[i - 1] < 0 && buffer[i] >= 0) {
            start = i;
            break; // interrupts a for loop
          }
        }
      }
      // start = 0;
      buffer[start] = 0; // Force the start to be exactly zero.

      // calculate a new end point such that we always
      // draw the same number of samples in each frame
      let end = start + buffer.length / 2;
      end = start + 200; // 200 produces good results here.

      let waveHeight = 1; // Adjust up by scale percentage.
      // drawing the waveform
      for (let i = start; i < end; i++) {
        let x1 = p.map(i - 1, start, end, 0, width);
        let y1 = p.map(buffer[i - 1] * waveHeight, -1, 1, 0, height);
        let x2 = p.map(i, start, end, 0, width);
        let y2 = p.map(buffer[i] * waveHeight, -1, 1, 0, height);
        p.line(x1 + x, y1 + y, x2 + x, y2 + y);
      }
    };
    new P5(sketch, this.$refs.canvas);
    // In case you need to reuse it, see the following uncommented code.
    // let myp5 = new P5(sketch, this.$refs.canvas);
    // console.log(myp5);
  },
};
</script>

<style lang="scss" scoped>
.canvas-div {
  position: relative;
}
canvas {
  //background: pink;
  z-index: 1000;
}
</style>
