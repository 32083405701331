<template>
  <div class="notification-container">
    <Transition>
      <div v-show="alerting" class="alert-container">
        <h1>{{ $store.state.alertHeading }}</h1>
        <p>{{ $store.state.alertDescription }}</p>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: "Notification",
  data() {
    return {
      alerting: false,
    };
  },
  computed: {
    alertId() {
      return this.$store.state.alertId;
    },
  },
  watch: {
    alertId() {
      this.triggerAlert();
    },
  },
  mounted() {
    // setInterval(() => {
    //   this.alerting = !this.alerting;
    // }, 1000);
  },
  methods: {
    triggerAlert() {
      this.alerting = true;
      setTimeout(() => {
        this.alerting = false;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-container {
  width: 100%;
  display: flex;
  justify-content: center;
  .alert-container {
    background-color: lightblue;
    padding: 4px 30px;
    border-radius: 10px;
    min-width: 200px;
    text-align: center;
    color: black;
    h1 {
      font-size: 1.8em;
      line-height: 20px;
    }
  }
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
}
</style>
