import { WebMidi } from "webmidi";
export default {
  namespaced: true,
  state: {
    ccListeners: [],
    lastReceivedEvent: {},
    midiReceived: false,
  },
  mutations: {},
  getters: {},
  actions: {
    registerCcCallback({ state }, callbackObj) {
      console.log("registerCcCallback");
      state.ccListeners.push(callbackObj);
    },
    setupMidiListeners({ state }) {
      console.log("setupMidiListeners");

      // Function triggered when WebMidi.js is ready
      function onEnabled() {
        // Inputs
        WebMidi.inputs.forEach((input) => {
          console.log("input: ", input.manufacturer, input.name);
          // Setup listeners.
          input.addListener("controlchange", (e) => {
            let evt = {
              channel: e.message.channel,
              cc: e.controller.number,
              value: Math.round(e.value * 100) / 100,
              rawValue: e.rawValue,
            };
            state.midiReceived = true;
            state.lastReceivedEvent = evt;
            state.ccListeners.forEach((fn) => fn(evt));
          });
        });

        // Outputs
        WebMidi.outputs.forEach((output) =>
          console.log("output: ", output.manufacturer, output.name)
        );
      }

      // See if midi is connected.
      WebMidi.enable()
        .then(onEnabled)
        .catch((err) => console.log(err));
    },
  },
};
