<template>
  <div id="app">
    <router-view v-if="$store.state.fileLoaded"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {
    let queryData = this.$route.query.d;
    if (queryData) {
      // Render single file.
      this.$store.dispatch("loadFromQueryString", queryData);
    } else {
      // Load warblerFiles from localstorage.
      this.$store.dispatch("loadFileFromBrowser");
    }
  },
};
</script>

<style>
body {
  margin: 0;
  overflow: hidden; /* Hide scrollbars */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
