import Vue from "vue";
import Vuex from "vuex";
import localforage from "localforage";
import midi from "@/store/modules/midi";
import remote from "@/store/modules/remote";

// import sortBy from "lodash/sortBy";
import debounce from "lodash/debounce";

import { compressToEncodedURIComponent } from "@kalkih/lz-string";
import { decompressFromEncodedURIComponent } from "@kalkih/lz-string";

import { fileOpen, supported, fileSave } from "browser-fs-access";

Vue.use(Vuex);

const STORE_NAME = "CodeWarbler/warbler";
const STORE_FILE_KEY = "activeFile";
const FILE_SYSTEM_PROJECT_ID = "warbler"; // Used by FileSystem API as a directory id.
const FILE_SYSTEM_EXTENSIONS = [".codewarbler"];
const FILE_SYSTEM_DESCRIPTION = "CodeWarbler files";

const defaultCode = `// Libraries available: Tone.js 14.7.77, Tonal.js 4.6.5, Lodash 4.17.21
const title = "Untitled file";

// Create a Tone.js oscillator
var t = new Tone.Oscillator(400);
t.volume.value = -12;
t.start();
t.toDestination();`;

const deboucedHistoryReplaceStateDelayMs = 1000;
const deboucedHistoryReplaceState = debounce(function (href) {
  console.log("deboucedHistoryReplaceState", href);
  window.history.replaceState({}, null, href);
}, deboucedHistoryReplaceStateDelayMs);

export default new Vuex.Store({
  modules: {
    midi: midi,
    remote: remote,
  },
  state: {
    forage: null,
    fileLoaded: false,
    queryFile: {},
    isQueryFile: false,
    browserFile: {},
    fileHandle: null,
    data: {},
    editorReference: null,
    alertId: 0,
    alertHeading: "",
    alertDescription: "",
    isRunning: false,
  },
  mutations: {
    setEditorReference(state, value) {
      state.editorReference = value;
    },
    setAlert(state, alertType) {
      if (alertType == "fileSave") {
        state.alertHeading = "File Saved";
        state.alertDescription = `${state.fileHandle.name} was updated.`;
      }
      // Trigger the alert.
      state.alertId = Math.random();
    },
    setIsRunning(state) {
      state.isRunning = true;
    },
    setIsNotRunning(state) {
      state.isRunning = false;
    },
  },
  getters: {
    activeFile(state) {
      if (state.isQueryFile) {
        return state.queryFile;
      }
      return state.data;
    },
    fileAsJson(state) {
      return JSON.stringify(state.data);
    },
    fileName(state) {
      return state.fileHandle?.name;
    },
  },
  actions: {
    async openFile({ state }) {
      if (supported) {
        console.log("Using the File System Access API.");
      } else {
        console.log("Using the fallback implementation.");
      }
      // Options are optional. You can pass an array of options, too.
      const options = {
        extensions: FILE_SYSTEM_EXTENSIONS,
        multiple: false,
        description: FILE_SYSTEM_DESCRIPTION,
        id: FILE_SYSTEM_PROJECT_ID,
        excludeAcceptAllOption: false,
      };

      const blob = await fileOpen(options);
      state.fileHandle = blob.handle;
      const file = await state.fileHandle.getFile();
      let jsonString = await file.text();
      // Send the code to the editor.
      let data = JSON.parse(jsonString);
      state.data = data;
      state.editorReference.getModel().setValue(data.code);
    },
    async saveFileToSystem({ state, dispatch, getters, commit }) {
      if (!state.fileHandle) {
        // No fileHandle, save as new.
        return dispatch("saveFileToSystemAsNew");
      }
      const blob = new Blob([getters.fileAsJson], {});
      await fileSave(
        blob,
        {
          fileName: state.fileHandle.name,
          description: FILE_SYSTEM_DESCRIPTION,
          extensions: FILE_SYSTEM_EXTENSIONS,
        },
        state.fileHandle
      );
      commit("setAlert", "fileSave");
    },
    async saveFileToSystemAsNew({ state, getters, commit }) {
      const blob = new Blob([getters.fileAsJson], {
        type: "text/json",
      });
      let filename = state.data.filename || "Untitled.codewarbler";
      let newFileHandler = await fileSave(
        blob,
        {
          fileName: filename,
          description: FILE_SYSTEM_DESCRIPTION,
          extensions: FILE_SYSTEM_EXTENSIONS,
          id: FILE_SYSTEM_PROJECT_ID,
        },
        null
      );
      state.fileHandle = newFileHandler;
      commit("setAlert", "fileSave");
    },
    loadFileFromBrowser({ state, dispatch }) {
      console.log("loadFileFromBrowser");
      if (!state.forage) {
        state.forage = localforage.createInstance({
          name: STORE_NAME,
        });
      }
      return state.forage.getItem(STORE_FILE_KEY).then((value) => {
        if (!value) {
          value = {
            id: STORE_FILE_KEY,
            data: {
              code: defaultCode,
              title: "Untitled file",
              datetime: Date.now(),
            },
          };
        } else {
          value = {
            id: STORE_FILE_KEY,
            data: value,
          };
        }
        state.browserFile = value;
        state.data = value.data;
        dispatch("updatePageTitle");
        state.fileLoaded = true;
      });
    },
    loadFromQueryString({ state, dispatch }, queryData) {
      let decompressedData = JSON.parse(
        decompressFromEncodedURIComponent(queryData)
      );
      if ("data" in decompressedData) {
        // Edge case for very early data URLs.
        decompressedData = decompressedData.data;
      }
      console.log("data" in decompressedData);
      state.queryFile = decompressedData;
      state.isQueryFile = true;
      state.fileLoaded = true;
      dispatch("updatePageTitle");
    },
    saveCurrentToFile({ getters, dispatch }) {
      let data = getters.activeFile;
      dispatch("saveFile", data);
      dispatch("saveFileToSystem");
    },
    updateCode({ getters, dispatch }, codeText) {
      let data = getters.activeFile;
      data.code = codeText;
      dispatch("saveFile", data);
    },
    updateTitle({ getters, dispatch }, title) {
      let data = getters.activeFile;
      if (title == data.title) {
        return;
      }
      data.title = title;
      dispatch("saveFile", data);
    },
    saveFile({ state, getters, dispatch }, data) {
      // Trigger alert dialog.
      data.datetime = Date.now();
      // Migrate .name to .title here (safe to remove this after March 2022)
      if (!data.title) {
        data.title = data.name;
      }
      delete data.name;
      delete data.snippet; // Migrate .snippet (safe to remove this after March 2022)
      data.libraryName = "Tone.js";
      data.libraryVersion = process.env.VUE_APP_TONE_VERSION;
      data.appName = "warbler";
      data.appVersion = process.env.VUE_APP_WARBLER_VERSION;
      if (state.fileHandle) {
        data.filename = state.fileHandle.name;
      }
      dispatch("updatePageTitle");
      state.data = data;
      if (state.isQueryFile) {
        // Query-based warblerFiles
        let jsonString = JSON.stringify(getters.activeFile);
        let compressed = compressToEncodedURIComponent(jsonString);
        const href = `${window.location.pathname}?d=${compressed}`;
        // Debounce replacing the URL state.
        deboucedHistoryReplaceState(href);
      } else {
        // Local warblerFiles.
        state.forage
          .setItem(STORE_FILE_KEY, data)
          .then((value) => {
            console.log("Saved!", value);
          })
          .catch(function (err) {
            // This code runs if there were any errors
            console.error(err);
          });
      }
    },
    gotoPermanentUrl({ getters }) {
      let jsonString = JSON.stringify(getters.activeFile);
      let compressed = compressToEncodedURIComponent(jsonString);
      let href = `https://codewarbler.github.io/warbler/${process.env.VUE_APP_WARBLER_VERSION}/?d=${compressed}`;
      if (process.env.NODE_ENV == "development") {
        href = `http://127.0.0.1:8080/warbler/${process.env.VUE_APP_WARBLER_VERSION}/?d=${compressed}`;
      }
      window.open(href, "_blank");
    },
    updatePageTitle({ getters }) {
      document.title = `${getters.activeFile.title} - CodeWarbler`;
    },
  },
});
