// import * as Tone from "@/tone";
import * as esprima from "esprima";
import sortBy from "lodash/sortBy";

const Tone = window.Tone;

const defaultWaveformDisplays = [
  "AmplitudeEnvelope",
  "DCMeter",
  "LFO",
  "Signal",
  "WaveShaper",
];
const defaultSpectrumDisplays = [];
const SCOPE = "scope";
const WAVEFORM = "waveform";
const SPECTRUM = "spectrum";
const METER = "meter";
const BUTTON = "button";
const SLIDER = "slider";

export class CodeWarblerVisuals {
  constructor(_tone) {
    if (_tone) {
      this._tone = _tone;
    } else {
      this._tone = Tone;
    }

    this.visuals = [];
    this.toneObjectDeclarations = [];
    this._title = "Untitled";
  }

  display(signal, name) {
    this.visuals.push({
      signal: signal,
      name: name,
      type: null, // Triggers auto-guess
    });
  }

  spectrum(signal, name) {
    this.visuals.push({
      signal: signal,
      name: name,
      type: SPECTRUM,
    });
  }

  scope(signal, name) {
    this.visuals.push({
      signal: signal,
      name: name,
      type: SCOPE,
    });
  }

  waveform(signal, name) {
    this.visuals.push({
      signal: signal,
      name: name,
      type: WAVEFORM,
    });
  }

  meter(signal, name) {
    this.visuals.push({
      signal: signal,
      name: name,
      type: METER,
    });
  }

  button(callback, name) {
    this.visuals.push({
      callback: callback,
      name: name,
      type: BUTTON,
    });
  }

  slider(callback, name, initialValue, midi) {
    this.visuals.push({
      callback: callback,
      name: name,
      type: SLIDER,
      midi: midi,
      initial: initialValue,
    });
  }

  _autoDisplay(signal, declarationObj) {
    // console.log("_autoDisplay", declarationObj);
    if (signal.connect) {
      this.visuals.push({
        signal: signal,
        name: undefined,
        declarationObj: declarationObj,
        type: null, // Triggers auto-guess
      });
    }
  }

  evaluateSource(text) {
    let syntax = esprima.parse(text, { loc: true });
    console.log("evaluateSource");
    console.log(syntax);

    let toneObjectDeclarations = [];
    // Get all root level Tone declarations.
    let variableDeclarations = syntax.body.filter(
      (i) => i.type == "VariableDeclaration"
    );
    variableDeclarations.forEach((i) => {
      console.log(i.declarations[0]);
      let identifier = i.declarations[0].id.name;
      let locStart = i.loc.start.line;
      let locEnd = i.loc.end.line;
      let isTone = i.declarations[0].init.callee?.object?.name == "Tone";
      if (
        i.declarations[0].init.callee?.object?.callee?.object?.name == "Tone"
      ) {
        isTone = true;
      }
      // console.log("identifier", identifier, isTone);
      if (isTone) {
        toneObjectDeclarations.push({
          identifier: identifier,
          locStart: locStart,
          locEnd: locEnd,
        });
      }
    });

    let uiObjectStatements = [];
    let expressionStatements = syntax.body.filter(
      (i) => i.type == "ExpressionStatement"
    );
    expressionStatements.forEach((i) => {
      console.log(i.expression);
      let isUi = i.expression.callee?.object?.name == "Ui";
      if (isUi) {
        let label = "";
        if (i.expression.arguments.length > 1) {
          label = i.expression.arguments[1].value;
        }
        let locStart = i.loc.start.line;
        let locEnd = i.loc.end.line;
        // console.log(isUi, label, locStart, locEnd);
        uiObjectStatements.push({
          label: label,
          locStart: locStart,
          locEnd: locEnd,
        });
      }
    });
    // console.log(toneObjectDeclarations);
    this.toneObjectDeclarations = toneObjectDeclarations;
    // console.log(uiObjectStatements);
    this.uiObjectStatements = uiObjectStatements;
  }

  annotateVisualsLineOfCode() {
    console.log("visuals", this.visuals.length);
    console.log("toneObjectDeclarations", this.toneObjectDeclarations.length);
    console.log("uiObjectStatements", this.uiObjectStatements.length);
    const numDefaultVisuals = 3;
    let numUiExpressions = this.uiObjectStatements.length;
    this.visuals.forEach((i, idx) => {
      console.log("-------------------------------");
      console.log(i);
      if (idx < numDefaultVisuals) {
        console.log("defaults, no line of code");
        i.locStart = null;
        i.locEnd = null;
      } else if (idx < numDefaultVisuals + numUiExpressions) {
        console.log("UI expressions");
        console.log();
        let item = this.uiObjectStatements[idx - numDefaultVisuals];
        i.locStart = item.locStart;
        i.locEnd = item.locEnd;
      } else {
        console.log("toneObject declarations");
        i.locStart = i.declarationObj.locStart;
        i.locEnd = i.declarationObj.locEnd;
      }
    });
  }

  get signalObjects() {
    let _signalObjects = [];

    this.annotateVisualsLineOfCode();
    console.log(this.visuals);
    if (this.visuals.length > 0) {
      _signalObjects = this.visuals.map((i) => {
        // Assign the correct type of visual based on the object (signal, osc, etc).
        // We can try to auto-set by default here.

        console.warn(i.signal);

        // Handle UIs before the signals.
        // UIs have less properties to check/configure.
        if (i.type == BUTTON) {
          return i;
        }
        if (i.type == SLIDER) {
          return i;
        }

        if (!i.type) {
          // Make best guess of display type here.
          i.type = SCOPE;

          // Most everything defaults to scope, so
          // only need to add waveform and spectrum defaults.
          if (defaultWaveformDisplays.includes(i.signal.name)) {
            i.type = WAVEFORM;
          }
          if (defaultSpectrumDisplays.includes(i.signal.name)) {
            i.type = SPECTRUM;
          }
          console.log(i.signal);
        }

        // Look for items without metered or analyized signals.
        if (i.signal.name == "Param") {
          return {
            renderValue: (signal) => signal.value,
            signal: i.signal,
            name: i.name || i.signal.name,
            type: WAVEFORM,
            locStart: i.locStart,
            locEnd: i.locEnd,
          };
        }

        if (i.signal.name == "Recorder") {
          return {
            renderValue: (signal) => (signal.state == "stopped" ? 0 : 1),
            signal: i.signal,
            name: i.name || i.signal.name,
            type: WAVEFORM,
            locStart: i.locStart,
            locEnd: i.locEnd,
          };
        }

        // Everything past here has to have an output.
        // Check to see if the i.signal has an output.
        if (!("output" in i.signal)) {
          console.warn("no output: ", i.signal);
          return i;
        }

        // Waveform
        if (i.type == WAVEFORM) {
          // Make Meter for the signal.

          // Changed to DCMeter for negative accuracy.
          // let signalMeter = new this._tone.Meter({
          //   normalRange: true,
          // });
          //

          let signalMeter = new Tone.DCMeter();
          i.signal.connect(signalMeter);

          return {
            meter: signalMeter,
            name: i.name || i.signal.name,
            type: WAVEFORM,
            locStart: i.locStart,
            locEnd: i.locEnd,
          };
        }
        // FFT
        if (i.type == SPECTRUM) {
          // Make the FFT
          let fftMono = new this._tone.Mono();
          let fft = new this._tone.Analyser({
            type: "fft",
            size: 512,
            channels: 1,
            //smoothing: 0.9
          });
          fftMono.connect(fft);
          i.signal.connect(fftMono);
          return {
            fft: fft,
            name: i.name || i.signal.name,
            type: SPECTRUM,
            locStart: i.locStart,
            locEnd: i.locEnd,
          };
        }
        // Scope
        if (i.type == SCOPE) {
          // Make the scope
          let waveformMono = new this._tone.Mono();
          let waveform = new this._tone.Analyser({
            type: "waveform", // Yes, waveform type in analyser
            size: 512,
            smoothing: 0.9,
            channels: 1,
          });
          waveformMono.connect(waveform);
          i.signal.connect(waveformMono);
          return {
            waveform: waveform,
            name: i.name || i.signal.name,
            type: SCOPE,
            locStart: i.locStart,
            locEnd: i.locEnd,
          };
        }
        // Meter
        if (i.type == METER) {
          // Make the main meter.
          // let meter = new this._tone.Meter({ channels: 2, smoothing: 0 }); // 14.7 version
          // NOTE: in 14.8, the following change will be necessary.
          let meter = new this._tone.Meter({ channelCount: 2, smoothing: 0 }); // 14.8 version
          meter.normalRange = true;
          i.signal.connect(meter);
          return {
            meter: meter,
            name: i.name || i.signal.name,
            type: METER,
            locStart: i.locStart,
            locEnd: i.locEnd,
          };
        }
      });
    }

    _signalObjects = sortBy(_signalObjects, (i) => {
      if (!i.locStart) {
        return -1;
      }
      return i.locStart;
    });

    return _signalObjects;
  }
}
