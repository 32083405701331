import toWav from "audiobuffer-to-wav";
import { WaveFile } from "wavefile";

export const createWavFromBlob = (blob, metaString = "") => {
  console.log("createWavFromBlob");

  const audioContext = new AudioContext();
  const fileReader = new FileReader();

  // Set up FileReader on loaded end event
  fileReader.onloadend = () => {
    const arrayBuffer = fileReader.result; // as ArrayBuffer;

    // // Convert array buffer into audio buffer
    audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
      console.log(audioBuffer);
      // Convert audiobuffer to a wav file.
      var wav = toWav(audioBuffer);
      var uint8View = new Uint8Array(wav);
      // Read the wav file into WaveFile for Riff editing.
      // const WaveFile = window.wavefile.WaveFile;
      var waveFile = new WaveFile();
      waveFile.fromBuffer(uint8View);

      // Add metadata to new wav file.
      //metaString = "x".repeat(1 * 1024 * 1024); // 1MB
      waveFile.setTag("CODE", metaString);
      waveFile.setTag(
        "FRMT",
        JSON.stringify({ format: "viztune", version: "0.1.1" })
      );
      // Check some of the file properties
      console.log(waveFile.container);
      console.log(waveFile.chunkSize);
      console.log(waveFile.fmt.chunkId);
      console.log(waveFile.listTags());

      // Change the bit depth and or sample rate.
      //waveFile.toBitDepth("12");
      //waveFile.toSampleRate(44100);

      // Call toBuffer() to get the bytes of the file.
      // You can write the output straight to disk:
      let wavBuffer = waveFile.toBuffer();

      console.log("Reopening as a test");
      var waveFileText = new WaveFile();
      waveFileText.fromBuffer(wavBuffer);
      console.log(waveFileText.listTags());

      // Construct a blob from new wavefile and download (or TODO store in browser storage).
      const blob = new Blob([wavBuffer], { type: "audio/wav" });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.download = "recording.wav";
      anchor.href = url;
      console.log(url);
      anchor.click();
    });
  };

  //Load blob
  fileReader.readAsArrayBuffer(blob);
};
