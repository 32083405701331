<template>
  <div>
    <div v-if="$store.state.remote.isAuthenticated">
      Logged in as {{ $store.state.remote.user.displayName }}
      <div>
        <LogoutButton />
      </div>
    </div>
    <div v-else>
      Not logged in
      <LoginButton />
    </div>
  </div>
</template>

<script>
import LogoutButton from "@/components/LogoutButton";
import LoginButton from "@/components/LoginButton";

export default {
  name: "Authentication",
  components: { LogoutButton, LoginButton },

  mounted() {
    this.$store.dispatch("remote/connectToFirestore");
  },
};
</script>

<style></style>
